/* src/components/Footer.css */

.footer {
    background-color: #000; /* Footer background color */
    color: #fff; /* Footer text color */
    height: 60px; /* Fixed height of the footer */
    width: 100%; /* Full width */
    position: fixed; /* Fixing the footer to the bottom */
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: space-around; /* Horizontally distribute content */
    padding: 0px; /* Padding around content */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.footer a {
    color: #fff; /* Accent color for links */
    margin: 0 15px; /* Horizontal spacing between icons */
    text-decoration: none; /* No underline by default */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.footer a:hover,
.footer a:focus { /* Hover and focus states for better accessibility */
    color: #e57300; /* Slightly different color on hover/focus */
    text-decoration: none; /* No underline on hover/focus */
}

/* Icon Size Adjustment */
.footer a .fa-icon {
    font-size: 24px; /* Adjust the font size to increase icon size */
}

/* Adjustments for smaller screens */
@media (max-width: 400px) {
    .footer {
        font-size: calc(9px + 1vmin); /* Slightly smaller font size */
        padding: 15px; /* Adjusted padding */
    }
}
