/* src/pages/ServiceRequest.css */

.service-request-container {
    max-width: 800px;
    margin: 0 auto; /* Center the container */
    padding: 30px; /* Increased padding for better spacing */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* More pronounced shadow for depth */
    background-color: #fff; /* Sets a background color */
    border-radius: 8px; /* Rounded corners */
}

.service-request-header {
    font-size: 28px; /* Slightly larger font size for header */
    font-weight: bold;
    color: #333; /* Improved readability */
    margin-bottom: 30px; /* Increased bottom margin for spacing */
}

.service-request-form {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Child elements stretch to fill the width */
}

.form-field {
    margin-bottom: 20px;
}

.label {
    display: block; /* Ensures label is on its own line */
    font-size: 18px; /* Slightly larger font size for labels */
    margin-bottom: 10px; /* Slightly increased bottom margin */
    font-weight: 600; /* Emphasis on labels */
}

.input, .textarea, .select {
    width: 100%;
    padding: 12px; /* Slightly increased padding */
    font-size: 16px;
    border: 1px solid #ddd; /* Lighter border color */
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

.textarea {
    resize: vertical;
    min-height: 120px; /* Increased minimum height */
}

.submit-button {
    background-color: var(--accent-color, #007bff); /* Changed to a more standard blue */
    color: #fff;
    padding: 15px 25px; /* Increased padding */
    font-size: 18px; /* Larger font size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase; /* Uppercase for better visibility */
}

.submit-button:hover,
.submit-button:focus { 
    background-color: #0056b3; /* Darker shade for hover and focus */
    outline: none; /* Removes default focus outline */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .service-request-container {
        padding: 20px;
    }

    .service-request-header {
        font-size: 24px; /* Adjusted font size */
    }
}
