/* src/assets/styles/global.css */

/* Base Styles */
html {
  box-sizing: border-box; /* Ensures consistent box-sizing */
  height: 100%; /* Maintains full height */
}

*, *::before, *::after {
  box-sizing: inherit; /* Inherits box-sizing from html */
}

body, #root {
  height: 100%; /* Ensures full height for app container */
  margin: 0; /* Resets margin */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased; /* Improves text rendering in Webkit browsers */
  -moz-osx-font-smoothing: grayscale; /* Improves text rendering in Firefox for macOS */
  line-height: 1.6; /* Improves readability */
  background-color: #f4f4f4; /* Optional: Sets a default background color */
  color: #333; /* Sets a default text color for better contrast */
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0; /* Resets top margin for headings */
  font-weight: normal; /* Avoids bold headings, can be customized */
}

p {
  margin-top: 0; /* Resets top margin for paragraphs */
}

a {
  color: var(--accent-color);
  text-decoration: none; /* Removes underline from links */
}

a:hover, a:focus {
  text-decoration: underline; /* Adds underline on hover/focus for better accessibility */
}

/* Code Formatting */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #eee; /* Optional: Adds background for code elements */
  padding: 2px 4px; /* Optional: Adds padding to code elements */
  border-radius: 4px; /* Optional: Rounds corners of code elements */
}

/* Theme Colors */
:root {
  --accent-color: #ff4500; /* Main accent color */
  --text-color: #333; /* Default text color */
  --background-color: #f4f4f4; /* Default background color */
}
