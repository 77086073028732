/* src/components/Header.css */

:root {
    --accent-color: #ff4500;  /* Orange color for visibility against black and white */
}

.header {
    background-color: #000;  /* Header Color - Black */
    padding: 10px 50px;
    display: flex;
    justify-content: space-between;  /* Changed to space-between */
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 4px 2px -2px gray;  /* Shadow for a slightly lifted effect */
}

.logo img {
    max-height: 50px;
    width: auto;
}

.navigation {
    margin-right: 20px; /* Space on the right */
}

.navigation a {
    margin: 0 10px;
    color: white;  /* Font Color for Header Nav Links */
    text-decoration: none;
    transition: color 0.3s ease;
    padding: 15px;
    
}

.navigation a:hover,
.navigation a.active-link {
    color: var(--accent-color);  /* Color on hover and for active link */
}

.menu-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: none; /* Initially hidden on larger screens */
}

.menu-btn span {
    display: block;
    background: white;  /* Hamburger Menu Icon Color - White for contrast */
    height: 3px;
    width: 25px;
    margin: 2px 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    
}

.menu-btn.open span:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
}

.menu-btn.open span:nth-child(2) {
    opacity: 0;
}

.menu-btn.open span:nth-child(3) {
    transform: translateY(-5px) rotate(-45deg);
}

@media (max-width: 768px) {
    .navigation {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: black;  /* Header Nav Hamburger Menu Color - Black */
    }

    .navigation.open {
        display: flex;
        animation: slideIn 0.3s ease-out;
    }

    .navigation a {
        font-size: 36px;
    }

    .menu-btn {
        display: block;
    }

    .header {
        justify-content: space-between; /* Ensures the menu button is on the right */
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
