/* Enhancing Form Layout and Styling */
form, #onSiteSection {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Increased width for better layout */
  margin: 20px auto; /* Increased margin for better spacing */
  padding: 20px; /* Added padding */
  background-color: #fff; /* Optional: Background color for form */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Shadow for depth */
  border-radius: 8px; /* Rounded corners for the form */
}

label, input, button, select, textarea {
  margin-bottom: 20px; /* Increased bottom margin for better spacing */
  width: 100%; /* Ensure full width */
}

input, select, textarea {
  padding: 10px; /* Padding for input fields */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners for input fields */
}

/* Highlighting valid input fields */
.valid-input {
  border-color: #28a745; /* Green border for valid input */
  background-color: #e0f2e9; /* Light green background */
}

/* Error Message Styling */
.error-message {
  color: #d9534f; /* Red color for error messages */
  margin-top: 5px;
  font-size: 0.9em;
}

/* Button Styling */
button {
  background-color: #ff4500;
  color: white;
  padding: 12px 20px; /* Increased padding for better touch target */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px; /* Rounded corners for the button */
}

button:hover {
  background-color: #e57300; /* Darker shade for hover effect */
}

/* Success Message Styling */
.successMessage {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}
