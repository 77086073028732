/* src/pages/Home.css */
h1{font-weight: bold;
font-size: 48px;}
.hero {
  background-image: url('../assets/photos_sent_over/performancepackage.jpg');
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the image */
  color: #fff;
  padding: 50px;
  text-align: center;
  position: relative;
  font-size: 24px; /* Example size, adjust as needed */
  
}

.hero::before {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black tint, adjust opacity as needed */
  z-index: 1;
}

.hero > * {
  position: relative;
  z-index: 2;
}

.about,
.services-overview,
.testimonials,
.contact-cta {
  padding: 50px;
  text-align: center;
  font-size: 24px; /* Example size, adjust as needed */
}

.cta-button {
  background-color: #ff4500; /* Button Color For All Buttons on the Page */
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #333;
  text-decoration: none;
}

blockquote {
  font-style: italic;
}

cite {
  display: block;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
}

/* Add to your Home.css */
.contact-cta {
  padding-bottom: 100px; /* Adjust as per your footer height */
}

.brands {
  padding: 50px;
  text-align: center;
  font-size: 24px; /* Example size, adjust as needed */
  background-color: #f2f2f2; /* Light background for contrast */
}

.brand-logos img {
  max-width: 300px; /* Adjust as needed */
  margin: 0 20px; /* Adjust as needed */
  transition: transform 0.3s ease;
}
.brand-logos img:hover {
  transform: scale(1.1);
}

/* Responsive CSS for smaller screens */
@media screen and (max-width: 767px) {
  .about,
  .services-overview,
  .testimonials,
  .contact-cta,
  blockquote,
  cite,
  .brands {
    font-size: 14px; /* Adjusting the font size for smaller screens */
  }

  h1 {
    font-size: 24px; /* Optionally adjusting the h1 font size for smaller screens */
  }

  /* Adjusting the button size if needed */
  .cta-button {
    padding: 8px 16px;
    font-size: 16px;
  }

  .brand-logos img {
    max-width: 250px; /* Adjust as needed */
  }
}