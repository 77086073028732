/* src/pages/ContactUs.css */
.contact-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.business-card {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.contact-us-header {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.contact-info h2 {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
}

.contact-info p {
    margin-bottom: 10px;
    color: #555;
}

.contact-info a {
    color: #ff4500;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-info a:hover {
    color: #e33d00;
}

.cta-button {
    background-color: #ff4500;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #e33d00;
}

.icon {
    margin-right: 5px;
    vertical-align: middle;
}
