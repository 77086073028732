/* src/pages/Services.css */

.services-container {
  padding: 20px; /* Increased top and side padding for better spacing */
  padding-bottom: 80px; /* Specific bottom padding */
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.services-grid {
  display: grid; /* Changed to grid layout for better control */
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid items */
  gap: 30px; /* Increased gap for better spacing */
  justify-content: center;
}

.service-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added subtle shadow for depth */
  overflow: hidden;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  display: flex; /* Flex layout for consistent card height */
  flex-direction: column; /* Stack items vertically */
}

.service-card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
}

.service-card h2 {
  font-size: 20px; /* Increased font size */
  font-weight: bold; /* Bold font for title */
  margin: 10px 0;
  padding: 0 20px;
}

.service-card p {
  color: #555;
  font-size: 16px;
  line-height: 1.5; /* Increased line height for readability */
  margin: 10px 0;
  padding: 0 20px;
  flex-grow: 1; /* Allows paragraph to fill available space */
}

.service-image {
  width: 100%;
  height: 200px; /* Adjust as needed */
  object-fit: cover;
}
