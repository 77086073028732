/* src/components/App.css */

/* Main App Container */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the app fills the whole viewport height */
  position: relative;
  background-color: #f4f4f4; /* Background color for the whole app */
}

/* Content Area */
.App-content {
  flex: 1; /* Flex grow to take available space */
  padding-top: 90px; /* Adjust based on the header's height */
  background: #fff; /* Different background for content area */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Adds depth with a subtle shadow */
  overflow-y: auto; /* Ensures vertical content is scrollable if it overflows */
  width: 100%; /* Ensures the content area uses full width */
  max-width: 1200px; /* Maximum width of the content */
  margin: 0 auto; /* Center the content horizontally */
  padding-left: 15px; /* Adds some padding on the left */
  padding-right: 15px; /* Adds some padding on the right */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .App-content {
    padding-top: 90px; /* Smaller padding for smaller screens */
  }

  /* Consider adding additional responsive styles */
  /* Example: Reducing side paddings on smaller screens */
  .App-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

